// react component for the EPK page
import '../styles/Epk.css';

import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Footer from './Footer';
import SocialLinks from './SocialLinks';



const Epk = ({ isSeparatePage }) => {


    useEffect(() => {
        // if url is /epk, change the title to "Plastic Waste Band - EPK"
        if (isSeparatePage) {
            document.title = 'Plastic Waste Band - EPK';
        }

    }, []);

    return (
        <div className="epk">
            {/* <div class="parallax"></div> */}

            <div className='main'>
                {isSeparatePage && <h1>Plastic Waste Band - EPK</h1>}
                {/* <p>EPK Page</p> */}
                {/* <Link to="/">Home</Link> */}
                <h3>Bio 50 words:</h3>
                <p>
                    The Plastic Waste Band is a Montreal-based psychedelic jazz band. Inspired by the world’s looming environmental collapse, they play all original music and seek to push the boundaries of what a jazz band can be.
                </p>
                <h3>Bio 100 words:</h3>
                <p>
                    Inspired by the world’s looming environmental collapse, The Plastic Waste Band seeks to push the boundaries of what a jazz band can be. Their sound is born out of jazz and improvised music but draws from diverse influences of psychedelic rock, fusion, and beyond. Following the 2017 release of their self-titled debut album, they donated all proceeds from the album’s sales to charities dedicated to environmental conservation. Their second album, “CRUSHED,” was released on Montreal label Florafone Records in September 2019, and their third, “Revelations EP,” in 2020.
                </p>
                <h3>Bio 150 words:</h3>
                <p>
                    With a sound equally identifiable and eclectic, The Plastic Waste Band has carved a new niche for itself from within Montreal’s creative music scene. Born out of jazz and improvised music but drawing from their diverse influences of psychedelic rock, fusion, and beyond, The Plastic Waste Band’s live shows carry the audience through moments of meditative serenity and fiery spontaneity. Layered saxophones, affected guitar, and driving bass and drums sweep the listener into disparate musical environments that feel constantly fresh.
                </p>
                <p>
                    Inspired by the world’s looming environmental collapse, The Plastic Waste Band seeks to push the boundaries of what a jazz band can be. Following the 2017 release of their self-titled debut album, they donated all proceeds from the album’s sales to charities dedicated to environmental conservation. Their second album, “CRUSHED,” was released on Montreal label Florafone Records in September 2019, and their third, “Revelations EP,” in 2020.
                </p>
                <h3>Bio 300 words:</h3>
                <p>
                    With a sound equally identifiable and eclectic, The Plastic Waste Band has carved a new niche for itself from within Montreal’s creative music scene. Born out of jazz and improvised music but drawing from their diverse influences of psychedelic rock, fusion, and beyond, The Plastic Waste Band’s live shows carry the audience through moments of meditative serenity and fiery spontaneity. Layered saxophones, affected guitar, and driving bass and drums sweep the listener into disparate musical environments that feel constantly fresh.
                </p>
                <p>
                    Inspired by the world’s looming environmental collapse, The Plastic Waste Band seeks to push the boundaries of what a jazz band can be. Following the 2017 release of their self-titled debut album, they chose to donate all proceeds from the album’s sales to charities dedicated to environmental conservation. Their second album, “Crushed,” was released on Montreal label Florafone Records in September 2019. It is centered around the issue of creating art in the face of our inevitable destruction, as well as the beauty that can grow from its ruins. Their latest album, “Revelations EP,” released in 2020, builds upon this apocalyptic theme and firmly establishes them as a band with a sound uniquely their own.
                </p>
                <p>
                    The Plastic Waste Band is a collective comprised of accomplished musicians from all over the U.S. and Canada. Saxophonists Chris Edmondson and Claire Devlin, both having distinctive sounds as individuals, blend together beautifully to shape the band’s melodic voice. Bassist Ethan Cohn comes from a richly diverse musical background that allows him to forge his personal role on the bass. Drummer John Buck lends his acute sense of touch and groove to provide a supportive but flexible musical foundation. Jean-Michel Leblanc, as inventive in his use of effects as in his guitar playing, has occupied roles in rock and jazz bands before joining The Plastic Waste Band. Since their founding in late 2016, The Plastic Waste Band has performed regularly at many of eastern Canada’s major venues.
                </p>
            </div>


            <div>
                <h2>Press Photos</h2>
                <a className='tech-rider-link' target="_blank" href="https://drive.google.com/drive/folders/1TYfLTCGGullGUO-zNcK2RW9O1XCsk5ap?usp=sharing">https://drive.google.com/drive/folders/1TYfLTCGGullGUO-zNcK2RW9O1XCsk5ap?usp=sharing</a>
            </div>

            <div>
                <h2>Tech Rider</h2>
                <a className='tech-rider-link' target="_blank" href="https://docs.google.com/drawings/d/1CarY7a5E-PNOATSlAjQC6oBnSvaM-AaORsA62fY9154/edit">https://docs.google.com/drawings/d/1CarY7a5E-PNOATSlAjQC6oBnSvaM-AaORsA62fY9154/edit</a>
            </div>

            <div>
                <h2>Social Links</h2>
                <h3>Instagram: <a target="_blank" href="https://www.instagram.com/plasticwasteband/">@plasticwasteband</a></h3>
                <h3>Facebook: <a target="_blank" href="https://www.facebook.com/plasticwasteband">@plasticwasteband</a></h3>
                <h3>Youtube: <a target="_blank" href="https://www.youtube.com/@plasticwasteband">@plasticwasteband</a></h3>
                <h3>Spotify: <a target="_blank" href="https://open.spotify.com/artist/2TQ3e3ITAyGHNTzJwiHPlc?si=TCjp1uleQdm8FvnQYJz1zA">The Plastic Waste Band</a></h3>
                <h3>Bandcamp: <a target="_blank" href="https://plasticwaste.bandcamp.com/">plasticwaste</a></h3>




            </div>

        </div>
    );
}

export default Epk;