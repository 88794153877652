import Footer from './components/Footer';
import SocialLinks from './components/SocialLinks';
import './styles/Home.css';
import MyNav from './components/Navbar';
import Epk from './components/Epk';

// https://fontawesome.com/search

const VIDSCALE = 1.4;

function Home() {
  return (
    <div className="home">
      <MyNav />

      <div className='parallax-container container1'></div>
      {/* Coming soon... */}
      {/* <div className='buffer'></div> */}
      <div className='buffer-container container2' id='bio'>
        <h2>Bio</h2>
        <p className='bio'>
          With a sound equally identifiable and eclectic, The Plastic Waste Band has carved a new niche for itself from within Montreal’s creative music scene. Born out of jazz and improvised music but drawing from their diverse influences of psychedelic rock, fusion, and beyond, The Plastic Waste Band’s live shows carry the audience through moments of meditative serenity and fiery spontaneity. Layered saxophones, affected guitar, and driving bass and drums sweep the listener into disparate musical environments that feel constantly fresh.
          <br />
          <br />
          Inspired by the world’s looming environmental collapse, The Plastic Waste Band seeks to push the boundaries of what a jazz band can be. Following the 2017 release of their self-titled debut album, they chose to donate all proceeds from the album’s sales to charities dedicated to environmental conservation. Their second album, “Crushed,” was released on Montreal label Florafone Records in September 2019. It is centered around the issue of creating art in the face of our inevitable destruction, as well as the beauty that can grow from its ruins. Their latest album, “Revelations EP,” released in 2020, builds upon this apocalyptic theme and firmly establishes them as a band with a sound uniquely their own.
          <br />
          <br />
          The Plastic Waste Band is a collective comprised of accomplished musicians from all over the U.S. and Canada. Saxophonists Chris Edmondson and Claire Devlin, both having distinctive sounds as individuals, blend together beautifully to shape the band’s melodic voice. Bassist Ethan Cohn comes from a richly diverse musical background that allows him to forge his personal role on the bass. Drummer John Buck lends his acute sense of touch and groove to provide a supportive but flexible musical foundation. Jean-Michel Leblanc, as inventive in his use of effects as in his guitar playing, has occupied roles in rock and jazz bands before joining The Plastic Waste Band. Since their founding in late 2016, The Plastic Waste Band has performed regularly at many of eastern Canada’s major venues.
        </p>
      </div>
      <div className='parallax-container container3'></div>
      <div className='buffer-container container4' id='music'>
        <h2>Listen to the latest album</h2>
        <iframe
          className='spotify-embed'
          style={{ borderRadius: '12px' }}
          src="https://open.spotify.com/embed/album/2n14bll7j0WLoEHkqJgEFG?utm_source=generator&theme=0"
          width="60%"
          height="430"
          frameBorder="0"
          allowfullscreen=""
          allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
          loading="lazy"
        ></iframe>
      </div>
      <div className='parallax-container container5'></div>
      <div className='buffer-container container6' id='video'>
        <h2>Watch</h2>

        {/* ~~~~~~~~~ Revelations ~~~~~~~~~ */}
        <div className='embed-container'>
          <iframe
            className='youtube-embed'
            src="https://www.youtube.com/embed/I9XeZFOM-dg"
            modestbranding={1}
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen>
          </iframe>
        </div>

        {/* ~~~~~~~~~ Canon ~~~~~~~~~ */}
        <div className='embed-container'>
          <iframe
            className='youtube-embed'
            src="https://www.youtube.com/embed/x_5RG4t8npA"
            // modestbranding={1}
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen>
          </iframe>
        </div>

        {/* ~~~~~~~~~ Faith ~~~~~~~~~ */}
        <div className='embed-container'>
          <iframe
            className='youtube-embed'
            src="https://www.youtube.com/embed/5DsWUQaX2I8"
            modestbranding={1}
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen>
          </iframe>
        </div>

      </div>
      {/* <div className='parallax-container container7'></div> */}
      <div className='buffer-container container8'>
        <h2>The Uncrushed Project</h2>
        <p className='uncrushed-description'>Released in the summer of 2021, the group commissioned three video artists to create music videos for three different songs, each from one of our three albums. This was a way to rerelease some of our past work in a visual format, as a way of showcasing the dynamic nature of the music, bringing new life to old pieces.</p>

        {/* ~~~~~~~~~ LIGO ~~~~~~~~~ */}
        <div className='embed-container'>
          <iframe
            className='youtube-embed'
            src="https://www.youtube.com/embed/lkYlFHJodTo"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen>
          </iframe>

        </div>

        {/* ~~~~~~~~~ Uncrushed ~~~~~~~~~ */}
        <div className='embed-container'>
          <iframe
            className='youtube-embed'
            src="https://www.youtube.com/embed/lcF2MTxe8-M"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen>
          </iframe>
        </div>

        {/* ~~~~~~~~~ The Third Axis ~~~~~~~~~ */}
        <div className='embed-container'>
          <iframe
            className='youtube-embed'
            src="https://www.youtube.com/embed/HABWMpoBQAQ"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen>
          </iframe>
        </div>

      </div>

      <div className='parallax-container container7'></div>
      <div className='buffer-container container10' id='epk'>

        <h2>EPK</h2>
        <Epk />

      </div>

      {/* <div className='parallax-container container11'></div> */}
      <div className='buffer-container container12' id='contact'>
        <h2>Contact</h2>
        {/* coming soon */}
        <p className='contact'>For booking, press, or other inquiries, please contact us at <span className='email-address'>theplasticwasteband@gmail.com</span></p>
        <br />
        <br />
        <br />
        <br />
        <br />
        <SocialLinks />
      </div>

    </div>

  );
}

export default Home;


// tech ride: https://docs.google.com/drawings/d/1CarY7a5E-PNOATSlAjQC6oBnSvaM-AaORsA62fY9154/edit
