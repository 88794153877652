import React from 'react';
import SocialLinks from './SocialLinks';

function MyNav() {
    return (
        <div className='stick-header'>
            <div className='nav-logo'></div>
            <nav className="nav-group">
                <a className='nav-item' href="#bio">Bio</a>
                <a className='nav-item' href="#music">Music</a>
                <a className='nav-item' href="#video">Video</a>
                <a className='nav-item' href="#epk">EPK</a>
                <a className='nav-item' href="#contact">Contact</a>
            </nav>
            <SocialLinks />
        </div>
    );
}

export default MyNav;